import { Outlet } from '@remix-run/react'

import AppNavbar from '~/components/AppNavbar'
import AppSidebar from '~/components/AppSidebar'
import HydrationZustand from '~/components/HydrationZustand'
import { Toaster } from '~/components/ui/sonner'

export default function AppLayout() {
  return (
    <>
      <HydrationZustand>
        <AppSidebar />
        <div className="min-h-screen bg-gray-100 lg:pl-72">
          <AppNavbar />
          <main className="py-10">
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              <div className="rounded-lg bg-white shadow">
                <div className="px-4 py-5 sm:p-6">
                  <Outlet />
                </div>
              </div>
            </div>
          </main>
          <Toaster />
        </div>
      </HydrationZustand>
    </>
  )
}
