import { Menu, Transition } from '@headlessui/react'
import * as icons from '@heroicons/react/24/outline'
import { useNavigate } from '@remix-run/react'
import { Link } from '@remix-run/react'
import { Command } from 'cmdk'
import { Fragment, useEffect, useRef, useState } from 'react'
import useSWR from 'swr'

import { useAuthStore } from '~/components/AuthStore'
import { useGlobalSearch } from '~/components/GlobalSearchStore'
import TenantSwitcher from '~/components/TenantSwitcher'

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

type GlobalSearchResult = {
  id: string
  tenant_id: string
  company_name: string
  legal_name: string
}
type GlobalSearchResults = {
  factories: GlobalSearchResult[]
  clients: GlobalSearchResult[]
}

function GlobalSearch() {
  const authStore = useAuthStore()
  const { isOpen, close, open } = useGlobalSearch()
  const [search, setSearch] = useState('')
  const navigate = useNavigate()
  const inputRef = useRef<HTMLInputElement>(null)
  const { data, error, isLoading } = useSWR<GlobalSearchResults>(
    search.length > 1 && `/api/search/global?q=${search}`,
    // @ts-ignore
    authStore.fetcher,
  )

  useEffect(() => {
    const down = (e: KeyboardEvent) => {
      if (e.key === 'k' && (e.metaKey || e.ctrlKey)) {
        e.preventDefault()
        if (isOpen) {
          setSearch('')
          close()
        } else {
          open()
        }
      } else if (e.code === 'KeyK') {
        // 27 == Escape
        e.preventDefault()
        setSearch('')
        close()
      }
    }
    document.addEventListener('keydown', down)
    return () => document.removeEventListener('keydown', down)
  }, [isOpen, close, open])

  useEffect(() => {
    if (isOpen && inputRef && inputRef.current) {
      inputRef.current.focus()
    }
  }, [isOpen])

  // useEffect(() => {
  //   async function globalSearch(q) {
  //     setLoading(true)
  //     // const res = await api.get('/search/global?q=${}')
  //     // setItems(res)
  //     setLoading(false)
  //   }
  //   globalSearch(search)
  // }, [search])

  if (!isOpen) return <></>
  if (error) {
    // TODO: add sentry error logging
    return <></>
  }

  function go(path: string) {
    navigate(path)
    setSearch('')
    close()
  }

  return (
    <div className="fixed inset-0 z-[100] flex items-center justify-center">
      <div
        className="absolute inset-0 z-[75] bg-gray-500 opacity-75"
        onClick={close}
      />
      <div className="z-[100] transform shadow-xl transition-all sm:w-full sm:max-w-xl">
        <div className="rounded-lg bg-white">
          <Command label="Command Menu">
            <Command.Input
              value={search}
              onValueChange={setSearch}
              ref={inputRef}
              className="rounded-t-lg"
            />
            <Command.List>
              <Command.Empty>Nada encontrado...</Command.Empty>
              <Command.Group heading="Páginas">
                <Command.Item onSelect={() => go('/app')} className="link">
                  <Link to="/app">
                    <div className="cmdk-icon">
                      <icons.HomeIcon />
                    </div>
                    <h4>Dashboard</h4>
                  </Link>
                </Command.Item>
                <Command.Item
                  onSelect={() => go('/app/segments')}
                  className="link"
                >
                  <Link to="/app/segments">
                    <div className="cmdk-icon">
                      <icons.TagIcon />
                    </div>
                    <div>
                      <h4>Segmentos</h4>
                    </div>
                  </Link>
                </Command.Item>
                <Command.Item
                  onSelect={() => go('/app/segments/new')}
                  className="link"
                >
                  <Link to="/app/segments">
                    <div className="cmdk-icon">
                      <icons.TagIcon />
                    </div>
                    <div>
                      <h4>Adicionar Segmento</h4>
                    </div>
                  </Link>
                </Command.Item>
                <Command.Item
                  onSelect={() => go('/app/product_categories')}
                  className="link"
                >
                  <Link to="/app/product_categories">
                    <div className="cmdk-icon">
                      <icons.TagIcon />
                    </div>
                    <div>
                      <h4>Categorias</h4>
                    </div>
                  </Link>
                </Command.Item>
                <Command.Item
                  onSelect={() => go('/app/product_categories/new')}
                  className="link"
                >
                  <Link to="/app/product_categories/new">
                    <div className="cmdk-icon">
                      <icons.TagIcon />
                    </div>
                    <div>
                      <h4>Adicionar Categoria</h4>
                    </div>
                  </Link>
                </Command.Item>
                <Command.Item
                  onSelect={() => go('/app/clients')}
                  className="link"
                >
                  <Link to="/app/clients">
                    <div className="cmdk-icon">
                      <icons.BuildingOfficeIcon />
                    </div>
                    <div>
                      <h4>Clientes</h4>
                    </div>
                  </Link>
                </Command.Item>
                <Command.Item
                  onSelect={() => go('/app/clients/new')}
                  className="link"
                >
                  <Link to="/app/clients/new">
                    <div className="cmdk-icon">
                      <icons.BuildingOfficeIcon />
                    </div>
                    <div>
                      <h4>Adicionar Cliente</h4>
                    </div>
                  </Link>
                </Command.Item>
                <Command.Item
                  onSelect={() => go('/app/factories')}
                  className="link"
                >
                  <Link to="/app/factories">
                    <div className="cmdk-icon">
                      <icons.BuildingOffice2Icon />
                    </div>
                    <div>
                      <h4>Fábricas</h4>
                    </div>
                  </Link>
                </Command.Item>
                <Command.Item
                  onSelect={() => go('/app/factories/new')}
                  className="link"
                >
                  <Link to="/app/factories/new">
                    <div className="cmdk-icon">
                      <icons.BuildingOffice2Icon />
                    </div>
                    <div>
                      <h4>Adicionar Fábrica</h4>
                    </div>
                  </Link>
                </Command.Item>
                <Command.Item
                  onSelect={() => go('/app/orders')}
                  className="link"
                >
                  <Link to="/app/orders">
                    <div className="cmdk-icon">
                      <icons.ShoppingCartIcon />
                    </div>
                    <div>
                      <h4>Pedidos</h4>
                    </div>
                  </Link>
                </Command.Item>
                <Command.Item
                  onSelect={() => go('/app/orders/new')}
                  className="link"
                >
                  <Link to="/app/orders/new">
                    <div className="cmdk-icon">
                      <icons.ShoppingCartIcon />
                    </div>
                    <div>
                      <h4>Adicionar Pedido</h4>
                    </div>
                  </Link>
                </Command.Item>
                <Command.Item
                  onSelect={() => go('/app/finances')}
                  className="link"
                >
                  <Link to="/app/finances">
                    <div className="cmdk-icon">
                      <icons.CurrencyDollarIcon />
                    </div>
                    <div>
                      <h4>Finanças</h4>
                    </div>
                  </Link>
                </Command.Item>
                <Command.Item
                  onSelect={() => go('/app/finances/new')}
                  className="link"
                >
                  <Link to="/app/finances/new">
                    <div className="cmdk-icon">
                      <icons.CurrencyDollarIcon />
                    </div>
                    <div>
                      <h4>Adicionar Movimentação Financeira</h4>
                    </div>
                  </Link>
                </Command.Item>
                <Command.Item
                  onSelect={() => go('/app/commissions')}
                  className="link"
                >
                  <Link to="/app/commissions">
                    <div className="cmdk-icon">
                      <icons.ReceiptPercentIcon />
                    </div>
                    <div>
                      <h4>Comissões</h4>
                    </div>
                  </Link>
                </Command.Item>
                <Command.Item
                  onSelect={() => go('/app/reports')}
                  className="link"
                >
                  <Link to="/app/reports">
                    <div className="cmdk-icon">
                      <icons.PrinterIcon />
                    </div>
                    <div>
                      <h4>Relatórios</h4>
                    </div>
                  </Link>
                </Command.Item>
              </Command.Group>
              {!isLoading && search.length > 1 && (
                <Command.Group heading="Fábricas">
                  {!isLoading && data?.factories?.length === 0 && (
                    <Command.Empty>Nenhuma fábrica encontrada.</Command.Empty>
                  )}
                  {!isLoading &&
                    data?.factories?.length !== 0 &&
                    data?.factories.map((factory) => (
                      <Command.Item
                        onSelect={() => go(`/app/factories/${factory.id}`)}
                        key={factory.id}
                        value={factory.company_name}
                        onClick={close}
                      >
                        <Link to={`/app/factories/${factory.id}`}>
                          <>
                            <div className="cmdk-icon">
                              <icons.BuildingOffice2Icon />
                            </div>
                            <div>
                              <h4>{factory.company_name}</h4>
                              <p>{factory.legal_name}</p>
                            </div>
                          </>
                        </Link>
                      </Command.Item>
                    ))}
                </Command.Group>
              )}
              {!isLoading && search.length > 1 && (
                <Command.Group heading="Clientes">
                  {!isLoading && data?.clients?.length === 0 && (
                    <Command.Empty>Nenhum cliente encontrado.</Command.Empty>
                  )}
                  {!isLoading &&
                    data?.clients?.length !== 0 &&
                    data?.clients.map((client) => (
                      <Command.Item
                        onSelect={() => go(`/app/clients/${client.id}`)}
                        key={client.id}
                        value={client.company_name}
                        onClick={close}
                      >
                        <Link to={`/app/clients/${client.id}`}>
                          <>
                            <div className="cmdk-icon">
                              <icons.BuildingOfficeIcon />
                            </div>
                            <div>
                              <h4>{client.company_name}</h4>
                              <p>{client.legal_name}</p>
                            </div>
                          </>
                        </Link>
                      </Command.Item>
                    ))}
                </Command.Group>
              )}
              {/*
              <Command.Group heading="Fábricas">
                {isLoading && (<Command.Loading><Skeleton className="w-[100px] h-[20px] rounded-full" /></Command.Loading>)}
                {OptionGroup(data, isLoading, go, 'factories')}
                      <icons.BuildingOffice2Icon className="h-6 w-6 shrink-0" />
              </Command.Group>
              */}
              {/*
              {optionGroup('Segmentos', 'segments')}
              {optionGroup('Categorias', 'product_categories')}
              */}
            </Command.List>
          </Command>
        </div>
      </div>
    </div>
  )
}

export default function AppNavbar() {
  const authStore = useAuthStore()
  const globalSearch = useGlobalSearch()
  const navigate = useNavigate()
  const signout = () => {
    authStore
      .http_post('/api/auth/sign_out', {})
      .then(() => {
        authStore.logout()
        navigate('/')
      })
      .catch((e) => {
        console.error({ e })
      })
  }
  const currentTenant = authStore.multipleTenants() && authStore.current_tenant
  const userName: string | null = authStore.name
  const userAvatar = userName
    ? (userName as string).charAt(0)?.toLocaleUpperCase()
    : '?'
  // const [globalSearch, toggleGlobalSearch] = useState(false)
  // const openGlobalSearch = globalSearchIsOpen

  return (
    <div className="border-b border-gray-200 bg-white lg:w-full">
      <div className="flex h-16 items-center gap-x-4 px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:mx-8 lg:px-0 lg:shadow-none">
        <div className="h-6 w-px bg-gray-200 lg:hidden" />
        <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
          <div
            className="relative flex flex-1"
            onClick={() => globalSearch.open()}
            onKeyDown={() => {}}
          >
            <div className="relative flex w-full items-center">
              <GlobalSearch />
              <icons.MagnifyingGlassIcon className="pointer-events-none absolute inset-y-0 left-0 mr-4 h-full w-5 text-gray-400" />
              <span className="pl-8 text-gray-400">Pesquisar</span>
            </div>
          </div>
          <div className="flex items-center gap-x-4 lg:gap-x-6">
            <div className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-200" />
            {currentTenant && authStore.tenants?.length > 1 && (
              <>
                <TenantSwitcher
                  tenants={authStore.tenants}
                  currentTenant={currentTenant}
                />
                <div className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-200" />
              </>
            )}
            {authStore.notifications.length > 1 && (
              <>
                <button
                  type="button"
                  className="-m-2.5 p-2.5 text-gray-400 hover:text-gray-500"
                >
                  <icons.BellIcon className="h-6 w-6" />
                </button>
                <div className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-200" />
              </>
            )}
            <Menu as="div" className="relative">
              <Menu.Button className="-m-1.5 flex items-center p-1.5">
                {userName && (
                  <>
                    <span className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-gray-500">
                      <span className="font-mono font-medium leading-none text-white">
                        {userAvatar}
                      </span>
                    </span>
                    <span className="hidden lg:flex lg:items-center">
                      <span className="ml-4 text-sm font-semibold leading-6 text-gray-900">
                        {authStore.name}
                      </span>
                      <icons.ChevronDownIcon className="ml-2 h-5 w-5 text-gray-400" />
                    </span>
                  </>
                )}
              </Menu.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 z-[10] mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                  <Menu.Item>
                    {({ active }) => (
                      <Link
                        to="/app/profile"
                        className={classNames(
                          active ? 'bg-gray-50' : '',
                          'block cursor-pointer px-3 py-1 text-sm leading-6 text-gray-900',
                        )}
                      >
                        Meu Perfil
                      </Link>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        type="button"
                        onClick={signout}
                        onKeyDown={() => {}}
                        className={classNames(
                          active ? 'bg-gray-50' : '',
                          'block cursor-pointer px-3 py-1 text-sm leading-6 text-gray-900',
                        )}
                      >
                        Deslogar
                      </button>
                    )}
                  </Menu.Item>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </div>
      </div>
    </div>
  )
}
