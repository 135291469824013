import {
  BuildingOffice2Icon,
  BuildingOfficeIcon,
  Cog6ToothIcon,
  CurrencyDollarIcon,
  HomeIcon,
  PrinterIcon,
  ReceiptPercentIcon,
  ShoppingCartIcon,
  TagIcon,
} from '@heroicons/react/24/outline'
import { useLocation } from '@remix-run/react'
import { Link } from '@remix-run/react'

import { Logo } from './public/Logo'

const navigation = [
  {
    name: 'Dashboard',
    href: '/app',
    icon: HomeIcon,
    current: (p: string) => p === '/app',
  },
  {
    name: 'Segmentos',
    href: '/app/segments',
    icon: TagIcon,
    current: (p: string) => p.startsWith('/app/segments'),
  },
  {
    name: 'Categorias',
    href: '/app/product_categories',
    icon: TagIcon,
    current: (p: string) => p.startsWith('/app/product_categories'),
  },
  // B2B? B2C?
  {
    name: 'Clientes',
    href: '/app/clients',
    icon: BuildingOfficeIcon,
    current: (p: string) => p.startsWith('/app/clients'),
  },
  {
    name: 'Fábricas',
    href: '/app/factories',
    icon: BuildingOffice2Icon,
    current: (p: string) => p.startsWith('/app/factories'),
  },
  {
    name: 'Pedidos',
    href: '/app/orders',
    icon: ShoppingCartIcon,
    current: (p: string) => p.startsWith('/app/orders'),
  },
  {
    name: 'Finanças',
    href: '/app/finances',
    icon: CurrencyDollarIcon,
    current: (p: string) => p.startsWith('/app/finances'),
  },
  {
    name: 'Comissões',
    href: '/app/commissions',
    icon: ReceiptPercentIcon,
    current: (p: string) => p.startsWith('/app/commissions'),
  },
  {
    name: 'Relatórios',
    href: '/app/reports',
    icon: PrinterIcon,
    current: (p: string) => p.startsWith('/app/reports'),
    children: [
      {
        name: 'Engineering',
        href: '#',
        current: (p: string) => p.startsWith('/app/reports/report1'),
      },
      {
        name: 'Human Resources',
        href: '#',
        current: (p: string) => p.startsWith('/app/reports/report2'),
      },
      {
        name: 'Customer Success',
        href: '#',
        current: (p: string) => p.startsWith('/app/reports/report3'),
      },
    ],
  },
  // { name: 'Calendário', href: '/app/calendar', icon: CalendarDaysIcon, current: (p: string) => p.startsWith('/app/calendar') },
  // { name: 'Drive', href: '/app/drive', icon: InboxStackIcon, current: (p: string) => p.startsWith('/app/drive') },
  // { name: 'Webmail', href: '/app/webmail', icon: AtSymbolIcon, current: (p: string) => p.startsWith('/app/webmail') },
]

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

export default function AppSidebar() {
  const location = useLocation()
  const pathname = location.pathname
  // const pathname = usePathname()

  return (
    <div className="hidden lg:fixed lg:inset-y-0 lg:z-0 lg:flex lg:w-72 lg:flex-col">
      <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6 pb-4">
        <div className="flex h-16 shrink-0 items-center">
          <div className="h-8 w-auto">
            <Logo />
          </div>
        </div>
        <nav className="flex flex-1 flex-col">
          <ul className="flex flex-1 flex-col gap-y-7">
            <li>
              <ul className="-mx-2 space-y-1">
                {navigation.map((item) => (
                  <li key={item.name}>
                    <Link
                      to={item.href}
                      unstable_viewTransition
                      className={classNames(
                        pathname && item.current(pathname)
                          ? 'bg-gray-50 text-indigo-600'
                          : 'text-gray-700 hover:bg-gray-50 hover:text-indigo-600',
                        'group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6',
                      )}
                    >
                      <item.icon
                        className={classNames(
                          pathname && item.current(pathname)
                            ? 'text-indigo-600'
                            : 'text-gray-400 group-hover:text-indigo-600',
                          'h-6 w-6 shrink-0',
                        )}
                      />
                      {item.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </li>
            <li className="mt-auto">
              <Link
                to="/app/settings"
                unstable_viewTransition
                className={classNames(
                  'group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-indigo-600',
                  pathname?.startsWith('/app/settings')
                    ? 'bg-gray-50 text-indigo-600'
                    : 'text-gray-700 hover:bg-gray-50 hover:text-indigo-600',
                )}
              >
                <Cog6ToothIcon className="h-6 w-6 shrink-0 group-hover:text-indigo-600" />
                Configurações
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  )
}
