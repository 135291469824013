import { Listbox, Transition } from '@headlessui/react'
import { ChevronUpDownIcon } from '@heroicons/react/24/outline'
import { useNavigate } from '@remix-run/react'
import { Fragment } from 'react'

import { Tenant, useAuthStore } from '~/components/AuthStore'

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

type TenantSwitcherProps = {
  tenants: Tenant[]
  currentTenant: Tenant
}
export default function TenantSwitcher({
  tenants,
  currentTenant,
}: TenantSwitcherProps) {
  const authStore = useAuthStore()
  const navigate = useNavigate()
  const onChange = (tenant: Tenant) => {
    authStore.changeTenant(tenant)
    navigate('/app')
  }

  return (
    <Listbox value={currentTenant} onChange={onChange}>
      {({ open }) => (
        <div className="relative">
          <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm sm:leading-6">
            <div>
              <div className="flex items-center">
                <div className="inline-flex h-5 w-5 items-center justify-center rounded-full bg-gray-500">
                  <span className="font-medium leading-none text-white">
                    {currentTenant.name[0].toLocaleUpperCase()}
                  </span>
                </div>
                <span className="ml-3 block truncate">
                  {currentTenant.name}
                </span>
              </div>
              <div className="pointer-events-none absolute inset-y-0 right-0 z-0 ml-3 flex items-center pr-2">
                <ChevronUpDownIcon className="h-5 w-5 text-gray-400" />
              </div>
            </div>
          </Listbox.Button>
          <Transition
            show={open}
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="absolute z-50 mt-1 max-h-56 w-64 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {tenants.map((tenant) => (
                <Listbox.Option
                  key={tenant.id}
                  className={({ active }) =>
                    classNames(
                      active ? 'bg-indigo-600 text-white' : 'text-gray-900',
                      'relative z-0 cursor-default select-none py-2 pl-3 pr-9',
                    )
                  }
                  value={tenant}
                >
                  {() => (
                    <>
                      <div className="flex items-center">
                        <span className="inline-flex h-5 w-5 items-center justify-center rounded-full bg-gray-500">
                          <span className="w-5 text-center font-mono font-medium leading-none text-white">
                            {tenant.name[0]}
                          </span>
                        </span>
                        <span
                          className={classNames(
                            tenant.id == currentTenant.id
                              ? 'font-semibold'
                              : 'font-normal',
                            'ml-3 block truncate',
                          )}
                        >
                          {tenant.name}
                        </span>
                      </div>
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      )}
    </Listbox>
  )
}
