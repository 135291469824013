// import axios from 'axios'
import { create } from 'zustand'

// import { persist } from "zustand/middleware"

type GlobalSearchState = {
  isOpen: boolean
  open: () => void
  close: () => void
}

export const useGlobalSearch = create<GlobalSearchState>((set) => ({
  isOpen: false,
  open: () => set({ isOpen: true }),
  close: () => set({ isOpen: false }),
}))
